export const ALERT_SAVE_SUCCESS = "Save Success";
export const ALERT_SAVE_FAILED = "Save Failed";
export const ALERT_DELETE_SUCCESS = "Delete Success";
export const ALERT_DELETE_FAILED = "Delete Failed";
export const ALERT_UNSUBSCRIBE_SUCCESS = "Unsubscribe Success";
export const ALERT_UNSUBSCRIBE_FAILED = "Unsubscribe Failed";
export const ALERT_LOGIN_FAILED = "Email/Password is incorrect";
export const ALERT_FETCH_SUCCESS = "Fetch Success";
export const ALERT_FETCH_FAILED = "Fetch Failed. Please retry later.";
export const ALERT_ULOAD_SUCCESS = "Upload Success";
export const ALERT_UPLOAD_FAILED = "Upload Failed";
export const ALERT_LOAD_CONFIG = "Load Filter Success";
export const ALERT_EXPORT = "Export Success";
export const ALERT_PASSWORD_RESET_SUCCESS = "Please check your inbox for a new password";
export const ALERT_SOMETHING_WENT_WRONG = "Something went wrong. Please contact your Administrator.";
export const ALERT_SUCCESS_SEND_EMAIL = "Fantastic! Your email will be sent shortly";
export const ALERT_SUCCESS_SEND_TEXT = "Fantastic! Your text will be sent shortly";
export const ALERT_UNVALIDATED_DOMAIN = "Whoops, it looks like your email domain isn't currently setup so we can't send emails. Please go to Settings > Domain to sort this";
export const ALERT_DOMAIN_SET_DEFAULT = "Domain is set as default";
export const ALERT_DOMAIN_HAS_BEEN_DELETED = "Domain has been deleted!";
export const ALERT_INCORRECT_EMAIL_FORMAT = "Email format is incorrect";
export const ALERT_SYNC_SUCCESS = "Sync Success";

export const CONFIRMATION_UNSUBSCRIBE_AUDIENCE_TITLE = "Unsubscribe Audience(s)";
export const CONFIRMATION_UNSUBSCRIBE_AUDIENCE = "Are you sure want to unsubscribe the audience(s) ?";
export const CONFIRMATION_DELETE_AUDIENCE_TITLE = "Remove Audience(s)";
export const CONFIRMATION_DELETE_AUDIENCE = "Are you sure want to remove the audience(s) ?";
export const CONFIRMATION_CHANGE_TRIGGER_TYPE = "Change the selected trigger type?";
export const CONFIRMATION_CHANGE_TRIGGER_TYPE_MSG = "Your trigger data will be deleted and will revert back to default";
export const CONFIRMATION_CHANGE_TRIGGER_TYPE_TITLE = "Change Type";

export const FILE_TYPE_PDF = "pdf";
export const MOBILE_RESOLUTION = 768;

export const NODE_WIDTH = 200;
export const NODE_HEIGHT = 100;
export const HEADER_FONT_SIZE = 15;
export const DESCRIPTION_FONT_SIZE = 12;
export const NODE_TEXT_LIMIT = 40;
export const NODE_TITLE_LIMIT = 25;
export const LINE_HEIGHT = 20;
export const LINE_SHORT_HEIGHT = 20;
export const PRIMARY_COLOR = "#EF4277";
export const MANAGE_TITLE_LIMIT = 25;
export const DEFAULT_AUTO_CLOSE_ABANDON_BOT_CONVERSATION = 5;
export const TEXT_MESSAGE_MAX_CHAR = 1600;
export const MAX_RETRY_DOMAIN_AUTHENTICATION = 3;

export const LIVE_LINK_URL_GENERATE = process.env.REACT_APP_BASE_URL + "/chatbot/live/";
export const TEST_LINK_URL_GENERATE = process.env.REACT_APP_BASE_URL + "/chatbot/test/";
export const BOT_WIDGET_URL_GENERATE = process.env.REACT_APP_BASE_URL + "/widget/chatbot.js";

export const CUSTOM_FIELD_CONTAINS_TEXT = 'custom_field';

export const REACT_APP_ENVIRONMENT = {
  PRODUCTION: "Live",
  DEMO: "Demo",
  STAGING: "Staging",
  LOCAL: "Dev",
};

export const CandidateColumnName = {
  CHECKBOX_ROW: "rowNum",
  CANDIDATE_NAME: "candidateName",
  JOB_TITLE: "jobTitle",
  CURRENT_EMPLOYER: "currentEmployer",
  LOCATION: "location",
  RESUME: "updatedAt",
  PHONE_NUMBER: "phoneNumber",
  MOBILE_NUMBER: "mobileNumber",
  EMAIL_ADDRESS: "emailAddress",
  RECRUITER_RATING: "recruiterRating",
  ROI_AI_SCORE: "roiAiScore",
  LAST_CONTACTED: "lastContacted",
  RECENT_ACTIVITY: "recentActivity",
};

export const ClientColumnName = {
  CHECKBOX_ROW: "rowNum",
  CLIENT_NAME: "clientName",
  JOB_TITLE: "position",
  COMPANY: "companyName",
  PHONE_NUMBER: "phoneNumber",
  MOBILE_NUMBER: "mobileNumber",
  EMAIL_ADDRESS: "emailAddress",
  ROI_AI_SCORE: "roiAiScore",
};

export const LeadColumnName = {
  CHECKBOX_ROW: "rowNum",
  LEAD_NAME: "leadCandidateName",
  JOB_TITLE: "leadJobTitle",
  CURRENT_EMPLOYER: "leadCompany",
  INDUSTRY: "leadIndustry",
  LOCATION: "leadLocation",
  PHONE_NUMBER: "leadPhoneNumber",
  EMAIL_ADDRESS: "leadEmailAddress",
  RECRUITER_RATING: "leadRecruiterRating",
  LAST_CONTACTED: "leadLastContacted",
  RECENT_ACTIVITY: "leadRecentActivity",
  ROI_AI_SCORE: "leadRoiAIScore",
};

export const JobCandidateColumnName = {
  CHECKBOX_ROW: "rowNum",
  CANDIDATE_NAME: "candidateName",
  CANDIDATE_TITLE: "candidateTitle",
  TACTIC: "tactic",
  STATUS: "status",
  ROI_AI_SCORE: "roiAIScore",
  RESUME: "url",
  RECRUITER_RATING: "recruiterRating",
  TYPE: "typeString"
};

export const jobColumnName = {
  CHECKBOX_ROW: "rowNum",
  JOB_TITLE: "jobTitle",
  ORGANISATION: "jobOrganisation",
  STATUS: "jobStatus",
  LOCATION: "jobLocation",
  SOURCE: "jobSource",
  CLIENT_NAME: "jobClientName",
  INDUSTRY: "jobIndustry"
};

export const emailsGridColumnName = {
  CHECKBOX_ROW: "rowNum",
  CAMPAIGN_NAME: "name",
  CREATED_AT: "createdAt",
  CREATED_BY: "createdBy",
  UPDATED_AT: "updatedAt",
  UPDATED_BY: "updatedBy",
  STATUS: "status",
  DESCRIPTION: "description",

};

export const CandidateAudienceColumnName = {
  CHECKBOX_ROW: "rowNum",
  AUDIENCE_NAME: "audienceName",
  AUDIENCE_TYPE: "audienceTypeString",
  TOTAL_MEMBERS: "totalMembers",
  SOURCE: "source",
  CREATED: "created",
  LAST_ADDED_DATE: "lastAddedDate",
  LAST_ACTIVITY_DATE: "activity",
  LAST_ACTIVITY: "lastActivity",
  STATUS: "isLive",
  SUBSCRIBE: "isSubcribe",
  ACTION_BUTTON: "actionButton"
};

export const HeaderTabName = {
  TAB_CANDIDATE: "tab_candidates",
  TAB_CLIENT: "tab_clients",
  TAB_JOB: "tab_jobs",
  TAB_LEAD: "tab_leads",
  TAB_ACTIVITY: "tab_activities",
  TAB_SETTING_GENERAL: "tab_settings_general",
  TAB_CANDIDATE_DETAIL_ACTIVITY: "tab_candidate_detail_activity",
  TAB_CLIENT_DETAIL_ACTIVITY: "tab_client_detail_activity",
  TAB_CANDIDATE_DETAIL_AUTOMATION: "tab_candidate_detail_automation",
  TAB_CANDIDATE_DETAIL_AUDIENCE: "tab_candidate_detail_audience",
  TAB_CANDIDATE_DETAIL_MATCH: "tab_candidate_detail_match",
  TAB_JOB_DETAIL_ALL: "tab_job_detail_all",
  TAB_JOB_DETAIL_SOURCED: "tab_job_detail_sourced",
  TAB_AUTOMATION_MANAGE: "tab_automation_manage",
  TAB_AUTOMATION_STORY: "tab_automation_story",
  TAB_AUTOMATION_FINALISE: "tab_automation_finalise",
  TAB_AUTOMATION_GRID_TEMPLATE: "tab_automation_grid_template",
  TAB_AUTOMATION_GRID_EXISTING: "tab_automation_grid_existing",
  TAB_EMAIL_GRID_TEMPLATE: "tab_email_grid_template",
  TAB_EMAIL_GRID_EXISTING: "tab_email_grid_existing",
  TAB_EMAIL_TEMPLATE_STORY: "tab_email_template_story",
  TAB_EMAIL_EXISTING_STORY: "tab_email_existing_story",
  TAB_EMAIL_DETAILS: "tab_email_detail",
  TAB_EMAIL_SEND_TEST: "tab_email_send_test",
  TAB_SURVEY_MANAGE: "tab_survey_manage",
  TAB_SURVEY_STORY: "tab_survey_story",
  TAB_SURVEY_FINALISE: "tab_survey_finalise",
  TAB_SURVEY_GRID_EXISTING: "tab_survey_existing_grid",
  TAB_SURVEY_GRID_TEMPLATE: "tab_survey_template_grid",
  TAB_SETTING_OWNER_ASSIGNMENT: "tab_settings_owner_assignment",
  TAB_SETTING_WEB_ACTIVITY_TRACKER: "tab_settings_web_activity_tracker",
  TAB_ATS_SYNC: "tab_ats_sync",
  TAB_AUDIENCE: "tab_audience"
};

export const ListIndustry = [
  "Accounting",
  "Admin / Secretarial / Office Support",
  "Advertising / Media / Publishing",
  "Agribusiness & Farming",
  "Architecture & Interior Design",
  "Arts & Entertainment",
  "Banking & Finance",
  "Building & Construction",
  "Call Centre & Costumer Services",
  "Defence / Emergency / Security",
  "Education & Child Care",
  "Engineering",
  "Executive Management",
  "Government / Local Government",
  "Graduates",
  "Healthcare & Medicine",
  "Hospitality / Travel / Tourism",
  "HR & Recruitment",
  "Insurance",
  "IT & Telecomms",
  "Legal",
  "Manufacturing",
  "Marketing / PR / Events",
  "Mining / Oil & Gas / Utilities",
  "Multimedia / Design / New Media",
  "Real Estate / Property",
  "Retail",
  "Sales & Account Management",
  "Science & Biotechnology",
  "Sport & Recreation",
  "Trades & Services",
  "Transport / Logistics / Drivers",
  "Volunteer / Charity / Community"
];

export const ListSource = [
  "Manual Input"
];

export const ListJobStatus = [
  "Draft",
  "Closed",
  "Live"
];

export const ListJobType = [
  "Permanent",
  "Contract",
  "Temporary"
];

export const ListMonths = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
]

export const ListMonthsAbbreviaton = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
]

export const JobAdderGrantType = {
  AUTHORIZATION_CODE: "authorization_code",
  REFRESH_TOKEN: "refresh_token"
};

export const JobAdderResponseType = {
  CODE: "code"
};

export const JobAdderRedirectUri = process.env.REACT_APP_BASE_URL + "/admin/settings/atsfields";
export const JobAdderOAuthUrl = `${process.env.REACT_APP_JOBADDER_AUTH_URL}?response_type=${JobAdderResponseType.CODE}&redirect_uri=${JobAdderRedirectUri}&client_id=${process.env.REACT_APP_JOBADDER_CLIENT_ID}&scope=${encodeURI(process.env.REACT_APP_JOBADDER_SCOPE)}`;

export const AtsFieldsTokenVariable = {
  Address1: "address1",
  Address2: "address2",
  CandidateId: "candidate_id",
  City: "city",
  Country: "country",
  CurrentEmployer: "current_employer",
  Email: "email",
  FirstName: "first_name",
  JobTitle: "current_job_title",
  LastName: "last_name",
  Mobile: "mobile",
  State: "state",
  UrlUpdateData: "url_data",
  Zip: "zip",
  CandidateRating: "candidate_rating",
  Linkedin: "linkedin",
  Source: "source",
  Summary: "summary",
  CurrentWorkType: "current_work_type",
  UrlToken: "url_token",
  CurrentPayFrequency: "current_pay_frequency",
  CurrentSalary: "current_salary",
  DesiredLowSalary: "desired_low_salary",
  DesiredHighSalary: "desired_high_salary",
  DesiredPayFrequency: "desired_pay_frequency",
  CandidatePhoto: "candidate_photo",
  CandidateResume: "candidate_resume",
  CandidateNPS: "candidate_nps",
  ClientFirstName: "client_first_name"
};

export const RappidNodeType = {
  Email: "email",
  Delay: "delay",
  Trigger: "trigger",
  IfElse: "ifelse",
  Alert: "alert",
  UpdateRecord: "updateRecord",
  Text: "text"
};

export const RappidText = {
  AddStep: "Step",
  DelayStep: "Delay",
  EmailStep: "Email",
  TriggerStep: "Trigger",
  IfElseStep: "If/Else",
  AlertStep: "Alert",
  UpdateRecordStep: "Update Record",
  TextStep: "Text",
  YesStep: "Yes",
  NoStep: "No",
  IfElseText: "Does the contact match the following conditions?",
  HoverInformation: "Please hover to see details",
  CandidateCompletedText: "contact(s) completed in this step",
  CandidateEnteredText: "contact(s) entered in this step"
};

export const RappidCanvas = {
  Height: 2000,
  Width: 2000,
};

export const RappidNode = {
  RoiCustomTrigger: "Roi.CustomTrigger",
  RoiCustomNode: "Roi.CustomNode",
  RoiCustomBranching: "Roi.CustomBranching",
  RoiCustomLinkBranching: "Roi.CustomLinkBranching",
  InitTrigger: "initTrigger",
  InitStep: "initStep",
  AddStep: "addStep",
  AddStepYes: "addStepYes",
  AddStepNo: "addStepNo",
  AutomationStep: "automationStep",
  DeleteStep: "deleteStep",
  EllipseHeight: 50,
  EllipseWidth: 50,
  InitX: RappidCanvas.Width / 2,
  InitY: 30,
  RectHeight: 70,
  RectWidth: 180,
  RectHeightBranching: 100,
  RectWidthBranching: 260,
  DistanceY: 50,
  DistanceX: 65,
  TitleFontSize: 12,
  DescriptionFontSize: 10,
  ActiveElementBorderStrokeWidth: 3,
  InactiveElementBorderStrokeWidth: 1,
  PrimaryColor: "#EF4277",
  SecondaryColor: "#707070",
  YesColor: "#34eb40",
  NoColor: "#eb4034",
  BranchingX: 130,
  ParentGap: 50,
  ParentGapReport: 80,
  SiblingGap: 300,
};

export const RappidZoom = {
  ZoomStep: 0.2,
  ZoomMin: 0.4,
  ZoomMax: 2
};

export const DomainAuthentication = {
  Input: "Input",
  Verify: "Verify"
}

export const EmailSenderDefault = {
  Name: "test",
  Email: "test",
  Domain: "@roiai.net"
}

export const EmailReportsFieldName = {
  CAMPAIGN_NAME: "reports.campaignName",
  AUTOMATION_NAME: "reports.automationName",
  LAST_SEND_DATE: "reports.lastSendDate",
  TOTAL_SENT: "totalSent",
  OPENED_PERCENTAGE: "openedPercentage",
  LINKS_CLICKED_PERCENTAGE: "linkClickedPercentage",
  BOUNCED_PERCENTAGE: "bouncedPercentage",
  UNSUBSCRIBED_PERCENTAGE: "unsubscribePercentage",
  TOTAL_OPENED: "totalOpened",
  TOTAL_CLICKED: "totalLinkClicked",
  TOTAL_BOUNCED: "totalBounced",
  TOTAL_UNSUBSCRIBED: "reports.unsubscribed",

}

export const EmailClientReportsFieldName = {
  CAMPAIGN_NAME: "clientReportEmail.campaignName",
  AUTOMATION_NAME: "clientReportEmail.automationName",
  LAST_SEND_DATE: "clientReportEmail.lastSendDate",
  TOTAL_SENT: "totalSent",
  OPENED_PERCENTAGE: "openedPercentage",
  LINKS_CLICKED_PERCENTAGE: "linkClickedPercentage",
  BOUNCED_PERCENTAGE: "bouncedPercentage",
  UNSUBSCRIBED_PERCENTAGE: "unsubscribePercentage",
  TOTAL_OPENED: "totalOpened",
  TOTAL_CLICKED: "totalLinkClicked",
  TOTAL_BOUNCED: "totalBounced",
  TOTAL_UNSUBSCRIBED: "clientReportEmail.unsubscribed",
}

export const EmailReportsDetailFieldName = {
  CANDIDATE_NAME: "candidateName",
  CLIENT_NAME: "clientName",
  CLIENT_EMAIL: "clientEmail",
  EMAIL: "email",
  ACTIVITY_DATE: "activityDate",
  LINK_TITLE: "linkTitle",
  ORIGINAL_URL: "originalUrl",
  TOTAL: "total",
  IS_CLICKED: "isClicked",
  LINKS: "links",
  LAST_ACTIVITY_DATE: "lastActivityDate",
  SURVEYS: "surveys",
  SENT: "sent",
  STATUS: "status",
}

export const CampaignReportDetailsFieldName = {
  CANDIDATE_NAME: "candidateName",
  CAMPAIGN_NAME: "campaignName",
  AUTOMATION_NAME: "automationName",
  LAST_SEND_DATE: "lastSendDate",
  TOTAL: "total",
  LINK_TITLE: "linkTitle",
  LINKS: "links",
  ACTIVITY_DATE: "activityDate",
  ORIGINAL_URL: "originalUrl",
  EMAIL: "email",
  IS_CLICKED: "isClicked"
}

export const SurveyReportsFieldName = {
  SURVEY_NAME: "reports.surveyName",
  CAMPAIGN_NAME: "reports.campaignName",
  AUTOMATION_NAME: "reports.automationName",
  LAST_SEND_DATE: "reports.lastSendDate",
  TOTAL_USERS: "totalUsers",
  TOTAL_OPENED: "reports.totalOpened",
  TOTAL_COMPLETIONS: "reports.totalCompletions"
}

export const SurveyResponsesFieldName = {
  QUESTIONS: "surveyQuestion",
  RESPONSES: "surveyAnswer"
}

export const SurveyReportsDetailFieldName = {
  CANDIDATE_NAME: "candidateName",
  CLIENT_NAME: "clientName",
  CAMPAIGN_NAME: "campaignName",
  AUTOMATION_NAME: "automationName",
  COMPLETION_DATE: "completionDate",
  EMAIL: "email",
  MOBILE: "mobile",
  LAST_OPENED_DATE: "lastOpenedDate",
  COMPLETION: "completion",
  TOTAL_OPEN: "totalOpen"
}

export const TextReportsFieldName = {
  CAMPAIGN_NAME: "reportText.campaignName",
  AUTOMATION_NAME: "reportText.automationName",
  LAST_SEND_DATE: "reportText.lastSendDate",
  TOTAL_SENT: "totalSent",
  DELIVERED_PERCENTAGE: "deliveredPercentage",
  UNSUBSCRIBED_PERCENTAGE: "unsubscribePercentage",
  LINKS_CLICKED_PERCENTAGE: "linkClickedPercentage",
}

export const TextReportsDetailFieldName = {
  LINK_TITLE: "linkTitle",
  ORIGINAL_URL: "originalUrl",
  CLICKED: "total",
  CANDIDATE_NAME: "candidateName",
  MOBILE: "mobile",
  ACTIVITY_DATE: "activityDate",
  IS_CLICKED: "isClicked",
  LINKS: "links",
  LAST_ACTIVITY_DATE: "lastActivityDate",
  SURVEYS: "surveys",
  CAMPAIGN_NAME: "campaignName",
  STATUS: "status",
  IS_SUBSCRIBE: "isSubscribe"
}

export const CountryCode = [
  {
    name: "Australia",
    value: "au"
  },
  {
    name: "Canada",
    value: "ca"
  },
  {
    name: "Indonesia",
    value: "id"
  },
  {
    name: "Ireland",
    value: "ie"
  },
  {
    name: "New Zealand",
    value: "nz"
  },
  {
    name: "Philippines",
    value: "ph"
  },
  {
    name: "South Africa",
    value: "za"
  },
  {
    name: "United Kingdom",
    value: "gb"
  },
  {
    name: "USA",
    value: "us"
  }
];

export const WebActivityTrackerFormType = {
  DOMAIN: "domain",
  JOBVIEWPATH: "jobViewPath",
  APPLICATIONVIEWPATH: "applicationViewPath",
  CUSTOMVIEWPATH: "customViewPath"
}

export const AutomationReportCompletionFieldName = {
  CANDIDATE_NAME: "candidateName",
  LAST_ACTIVITY_DATE: "lastActivityDate",
}

export const AutomationReportClientCompletionFieldName = {
  CLIENT_NAME: "clientName",
  LAST_ACTIVITY_DATE: "lastActivityDate",
}

export const KnowledgeBaseUrl = "https://roi-ai.com/kb/";

export const MaxLengthActivityTitle = 80;

export const TripettoNodeType = {
  CHECKBOXES: "checkboxes",
  DROPDOWN: "dropdown",
  EMAIL: "email",
  FILE: "file",
  NUMBER: "number",
  PHONE: "phone",
  TEXT: "text",
  SCALE: "scale",
  DATE: "date"
}
